import React from 'react';
import QRGenerator from './QRGenerator';

function App() {
  return (
    <div className="App">
      <QRGenerator />
    </div>
  );
}

export default App;